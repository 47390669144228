import Blockies from "react-blockies";
import React, { Component } from "react";
import { Address } from "../components";
import { Button, Space } from "antd";
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

import './MessageForm.scss';
import './Message.scss';

class Message extends Component {
  constructor(props) {
    super(props)
  }
  
  gradientStopsAsString() {
    var decimalToHex = function(d, padding = 6) {
      var hex = Number(d).toString(16);
      padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;
  
      while (hex.length < padding) {
        hex = "0" + hex;
      }
  
      return "#" + hex;
    }
    
    var stops = this.props.message.gradientColors
    
    return stops.map((stop, idx) => {
      var decimal = idx / (stops.length - 1)
      
      return `${decimalToHex(stop)} ${decimal * 100}%`
    }).join(", ")
  }
  
  calculatedFontSize() {
    switch (parseInt(this.props.message.fontSize)) {
      case 14:
        return [14, 1.429, .16]
      case 16:
        return [16, 1.5, 0]
      case 20:
        return [20, 1.4, 0]
      case 24:
        return [24, 1.3, 0]
      case 28:
        return [28, 1.275, 0]
      case 32:
        return [32, 1.25, 0]
      case 42:
        return [42, 1.2, 0]
      case 60:
        return [60, 1.15, 0]
      case 76:
        return [76, 1.13, 0]
      case 92:
        return [92, 1.1, 0]
      default:
        return [16, 1.5, 0]
      break
    }
  }
   
  backgroundGradient() {
    if (this.props.message.fancyMessageId == 0) {
      return "#ffffff"
    }
    
    if (this.props.message.isRadialGradient) {
      var center = this.props.message.radialGradientCenterPct
      return `radial-gradient(at 50% 100%, ${this.gradientStopsAsString()})`
    } else {
      return `linear-gradient(${this.props.message.linearGradientAngleDeg}deg, ${this.gradientStopsAsString()})`
    }
  }
  
  render() {
    return (
      <div key={this.key} className="message"
        data-fancy-message-id={this.props.message.fancyMessageId}
        data-reply-id={this.props.message.inReplyToMessageId}
        data-reply-to-addr={this.props.message.recipient}
        style={{
          color: `${this.props.message.textColor}`,
          background: `${this.backgroundGradient()}`,
          // display: `${this.props.message.recipient ? "flex" : "none"}`,
      }}>
          
        <div className="messageText"
            style={{
              fontSize: this.calculatedFontSize()[0],
              lineHeight: this.calculatedFontSize()[1],
              letterSpacing: this.calculatedFontSize()[2]
            }}>
          <div className="messageTextInner">
            
          {/* <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,.25)",
              zIndex: '1',
              position: 'absolute',
              top: 0,
              left: '50%'
            }}></div> */}
            {this.props.message.text}</div>
        </div>
        
        <div className="messageFooter">
          <div className='tokenName'>
            FOREVER MSG #{this.props.message.messageId}
          </div>
          
          <div className='tokenMeta'>
            from&nbsp;&nbsp;&nbsp;<Address address={this.props.message.senderAddress.toLowerCase()} ensProvider={this.props.ensProvider}
            size="long" fontSize={12} noCopy={true} blockExplorer={"/"} /><br></br>
            date&nbsp;&nbsp;&nbsp;{(new Date(this.props.message.timestamp * 1000)).toLocaleString(
                  'sv-SE', {month: 'numeric', day: 'numeric', year: 'numeric',
                  timeZone: 'UTC'}
                )}
          </div>
        </div>
      </div>
    )
  }  
}

export default Message
