import { Divider, } from "antd";
import React, { useState, useEffect, useMemo } from "react";
import { Address, } from "../components";
import {
  Link,
  useParams
} from "react-router-dom";
import { isAddress } from "@ethersproject/address";
import { useLookupAddress, useResolveEnsName } from "eth-hooks/dapps/ens";
import { useEventListener } from "eth-hooks/events/useEventListener";

import MessageForm from "./MessageForm";
import Message from "./Message";
import "./Message.scss";
import { Button, Col, Row, Layout, PageHeader, Space } from "antd";
import Pluralize from 'react-pluralize'


export default function Profile({
  address,
  mainnetProvider,
  localProvider,
  tx,
  readContracts,
  writeContracts,
  blockExplorer,
  openSea,
  price
}) {
  const walletAddressOrENSName = useParams().walletAddressOrENSName;
  
  const resolvedAddress = useResolveEnsName(mainnetProvider, walletAddressOrENSName)
  var ensName = useLookupAddress(mainnetProvider, walletAddressOrENSName);
  
  var match = document.location.pathname.match(/^\/(\d+)$/)
  var messageId = match && match[1]
  
  const currentUserProfile = address == resolvedAddress
  const { Header, Footer, Sider, Content } = Layout
  // console.log('asdf', resolvedAddress)
  // console.log('asd2', ensName)
  // console.log('asdf', mainnetProvider)
  // var addressFromENS = useResolveEnsAddress(mainnetProvider, walletAddressOrENSName);
  
  // var resolvedAddress = useLookupAddress(walletAddressOrENSName);
  // console.log('asdfdsf',ensName.toString())
  // console.log('asdfdsf',addressFromENS)
  
  if (isAddress(walletAddressOrENSName)) {
    // const ens = useLookupAddress(mainnetProvider, walletAddressOrENSName);
    // console.log(ens)
    
    if (ensName && document.location.pathname != "/" + ensName) {
      window.history.replaceState(null, document.title, `/${ensName}`);
    }
  } else {
    if (!resolvedAddress && !messageId) {
      console.log("invalid page")
    }
  }
  
  const events = useEventListener(readContracts, "ForeverMessage", "CreateMessage", localProvider, 1);
  
  const foreverMessageAddress = readContracts.ForeverMessage && readContracts.ForeverMessage.address
  
  // console.log(events)
    
  // useEffect(() => {
  //   async function getSVGs() {
  //     var svgs = await Promise.all(Array.from(Array(9).keys()).slice().map(i => {
  //       i += 1
  //       return readContracts.ForeverMessage && readContracts.ForeverMessage.debugImage(i)
  //     }));
  //     setSVGs(svgs);
  //   }
  //   getSVGs();
  // }, [readContracts]);
  
  // console.log(events)
  
  // const [svgs, setSVGs] = useState([]);
  
  // useEffect(() => {
  //   async function getSVGs() {
  //     var svgs = await Promise.all(Array.from(Array(9).keys()).slice().map(i => {
  //       i += 1
  //       return readContracts.ForeverMessage && readContracts.ForeverMessage.debugImage(i)
  //     }));
  //     setSVGs(svgs);
  //   }
  //   getSVGs();
  // }, [readContracts]);
  
  var decimalToHex = function(d, padding = 6) {
    var hex = Number(d).toString(16);
    padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

    while (hex.length < padding) {
      hex = "0" + hex;
    }

    return "#" + hex;
  }
  
  var basicMessages = events.map(event => {
    return {
      text: event.args.text,
      timestamp: event.args.sentAt,
      senderAddress: event.args.sender,
      messageId: parseInt(event.args.messageId),
      fancyMessageId: parseInt(event.args.fancyMessageId),
      recipient: event.args.recipient,
      inReplyToMessageId: parseInt(event.args.inReplyToMessageId),
      textColor: decimalToHex(event.args.textColor),
      transactionHash: event.transactionHash,
      contract: event.address,
      gradientColors: event.args.gradientColors,
      isRadialGradient: event.args.isRadialGradient,
      linearGradientAngleDeg: event.args.linearGradientAngleDeg,
      fontSize: event.args.fontSize
    }
  }).filter(message => {
    return message.senderAddress == resolvedAddress || message.messageId == messageId
  })
  
  const [imageSrcs, setImages] = useState({});
  
  // const imageMap = {};
  // console.log(imageMap)
  
  // const tokenIds = events.
  //   filter(event => event.args.sender == resolvedAddress).map(event => event.args.tokenId.toNumber())
  
  // useEffect(() => {
  //   console.log('a', tokenIds)
  //   const fetchData = async () => {
  //     await Promise.all(tokenIds.map(async (tokenId) => {
  //       // console.log(imageSrcs)
  //       if (!imageSrcs[tokenId]) {
          
  //         var val = await readContracts.ForeverMessage.tokenImage(tokenId)
          
  //         setImages(prevState => {
  //           return {...prevState, [tokenId]: val}
  //           // return Object.assign({}, prevState, { [tokenId]: val });
  //         });
  //       }
  //     }))
  //   }
    
  //   fetchData();
  // }, [tokenIds]);
  
  const getImageSrcs = async (tokenIds) => {
    // var temp = {}
    
    // await Promise.all(tokenIds.forEach(async (id) => {
    //   var val = await readContracts.ForeverMessage.tokenImage(id)
    //   temp[id] = val
    // }))
  }
  
  // const newSrcs = useMemo(() => getImageSrcs(tokenIds), [tokenIds]);
  
  // console.log(tokenIds)
  // console.log(newSrcs)
  
  const [formOpen, setFormOpen] = useState(false);
  
  const validAddress = (resolvedAddress && resolvedAddress !== "0x0000000000000000000000000000000000000000")
  
  return (
    <Content className="profilePage" style={{paddingTop: 50}}>
      { validAddress && 
        <div className="header">
          {/* <h1 style={{ marginBottom: 0 }}>Forever Messages from</h1> */}

          {validAddress &&
              <Address address={resolvedAddress} ensProvider={mainnetProvider}
                size="long" fontSize={20} noCopy={true} blockExplorer={"/"} />}

          {currentUserProfile && <span>(You)</span>}
          <Divider />
          
          <h3>
          <Pluralize
            singular={'forever message'}
            count={basicMessages.length}
            
            zero={
              currentUserProfile ?
                'You have not sent any forever messages.'
                : 'This wallet had not sent any forever messages'
            }
            />
          </h3>

        </div>
      }
      
      {!formOpen && currentUserProfile && validAddress &&
        <Button onClick={() => setFormOpen(true)} type="primary" size="large" key="console">
          {basicMessages.length ? "Send a new message" : "Send your first"}
        </Button>
      }
      
        <MessageForm
            tx={tx}
            resolvedAddress={resolvedAddress}
            ensProvider={mainnetProvider}
            hidden={!currentUserProfile || !formOpen}
            writeContracts={writeContracts}
            price={price}
            readContracts={readContracts}
          ></MessageForm>
           
           {formOpen && <Divider></Divider>}
           
          {/* <div style={{display: 'flex', flexDirection: 'column-reverse'}}> */}
          {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '50px', transform: 'scale(1)', transformOrigin: 'top left', marginTop: 25, width: 1600, */}
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '25px', transform: 'scale(1)', transformOrigin: 'top left',
          position: 'relative', alignItems: 'flex-start', marginTop: 25
        }}>
          {basicMessages.slice().reverse().map((message, i) => 
            <>
              {/* <Space>
                <Button type="link" href={blockExplorer + "tx/" + message.transactionHash}>
                  Etherscan
                </Button>
                {message.tokenId > 0 &&
                  <Button type="link" href={openSea + message.contract + "/" + message.tokenId}>
                    OpenSea
                  </Button>
                }
              </Space> */}
              <div className="messageContainer">
                <Message key={i}
                  message={message}
                  ensProvider={mainnetProvider}
                  blockExplorer={blockExplorer}
                  openSea={openSea}
                ></Message>
              </div>
              
              <div style={{display: 'flex', gap: 15}}>
                {/* <a href={`https://testnets-api.opensea.io/api/v1/asset/${foreverMessageAddress}/${message.messageId}/validate`}>Validate</a> */}
                {/* <a href={`https://testnets-api.opensea.io/api/v1/asset/${foreverMessageAddress}/${message.messageId}/?force_update=true`}>View in OS API</a> */}
                {message.fancyMessageId > 0 && <a href={`${openSea}${foreverMessageAddress}/${message.messageId}`}>View on OpenSea</a>}
                <a href={blockExplorer + "tx/" + message.transactionHash}>Etherscan</a></div>
              

              {/* {console.log(imageSrcs[message.tokenId])} */}
              {/* { <img key={Math.random} src={imageSrcs[message.tokenId]} style={{width: 390, height: 'auto'}}></img>} */}
            </>
          )}
            
            {/* <div id="test" style={{width: 750, height: "100%", display: 'flex', flexDirection: 'column-reverse', gap: 10, justifyContent: 'flex-start', position: 'relative'}}>
              {svgs.map((svg, i) => {
                if (!svg.includes("nPg==")) {
                  return <img key={i} src={svg}></img>
                }
              })}
            </div> */}
      </div>
    </Content>
  );
}
