import { utils } from "ethers";
import React, { Component } from "react";
import { Address } from "../components";
import ReactTooltip from 'react-tooltip';
import { Form, Input, Button, Checkbox } from 'antd';
import { ethers } from "ethers";
import { Divider, Spin, Result, Modal } from "antd";
import { Link } from "react-router-dom";


import './MessageForm.scss';

class MessageForm extends Component {
  constructor(props) {
    super(props)
    this.state = this.initialState()
  }
  
  initialState() {
    return {
      calldataText: "",
      amendmentText: "",
      amendmentTextColor: "#000000",
      formStatus: "default",
      inReplyToMessageId: '',
      recipient: '',
      // recipient: '0x0000000000000000000000000000000000000000',
      amendmentGradientColor1: "#ffffff",
      amendmentGradientColor2: "#ffffff",
      amendmentGradientType: "linear",
      formStatus: "default",
      currentTextBytes: 0,
      currentTime: (new Date()),
      isFancy: true,
      gradientColorSpace: 'oklab',
      nextTokenId: 0,
      isSubmitting: false,
      success: false,
      fontSize: "16",
      gradientAngle: 45,
      modalVisible: false
    }
  }
  
  calculatedFontSize() {
    if (!this.state.isFancy) {
      return [16, 1.5, 0]
    }
    
    switch (parseInt(this.state.fontSize)) {
      case 14:
        return [14, 1.429, .16]
      case 16:
        return [16, 1.5, 0]
      case 20:
        return [20, 1.4, 0]
      case 24:
        return [24, 1.3, 0]
      case 28:
        return [28, 1.275, 0]
      case 32:
        return [32, 1.25, 0]
      case 42:
        return [42, 1.2, 0]
      case 60:
        return [60, 1.15, 0]
      case 76:
        return [76, 1.13, 0]
      case 92:
        return [92, 1.1, 0]
      default:
        return [16, 1.5, 0]
      break
    }
  }
  
  fancyCost() {
    var BigNumber = ethers.BigNumber
    
    var fixedCost = BigNumber.from(8758200 * 1e9)
    var variableCost = BigNumber.from(21600 * 1e9).mul(BigNumber.from(this.state.currentTextBytes))
    return fixedCost.add(variableCost)
  }
  
  fancyCostString() {
    var totalCost = ethers.utils.formatEther(this.fancyCost())
    
    // return `${totalCost} ETH (${(totalCost * this.props.price).toFixed(2)} USD)`
    return `$${(totalCost * this.props.price).toFixed(2)}`
  }
  
  messageBoardContract() {
    return this.props.writeContracts.ForeverMessage
  }
  
  toggleModal = async (e) => {
    e.preventDefault()
    this.setState({modalVisible: !this.state.modalVisible});
  }
  
  async componentDidMount() {
    if (this.props.readContracts && this.props.readContracts.ForeverMessage) {
      // var totalSupply = await this.props.readContracts.ForeverMessage.totalSupply()
      // this.setState({nextTokenId: totalSupply.toNumber() + 1})
    }
    
    this.intervalID = setInterval(() => {
      // this.resolveName()
      
      this.setState({
        currentTime: new Date()
        })
    }, 60 * 1000)
  }
  
  // async componentDidUpdate(prevProps, prevState) {
  //   if (this.props.readContracts && this.props.readContracts.ForeverMessage) {
  //     var totalSupply = await this.props.readContracts.ForeverMessage.totalSupply()
  //     this.setState({nextTokenId: totalSupply.toNumber() + 1})
  //   }
  // }
  
  enableFancy() {
    this.setState({
      isFancy: true,
      amendmentGradientColor1: "#949494",
      amendmentGradientColor2: "#ffffff"
    })
  }
  
  // async resolveName() {
  //   var name = (await ens.getName(inputValue)).name
    
  //   if (name) {
  //     console.log(name)
      
  //     this.setState({
  //       name: name
  //     })
  //   }
  // }
  
  gradientStopArray(opts = {format: 'hex'}) {
    var c1 = this.state.amendmentGradientColor1;
    var c2 = this.state.amendmentGradientType == 'none' ? c1 : this.state.amendmentGradientColor2;
    var colorSpace = this.state.gradientColorSpace
    var steps = 10
    
    let rgb = culori.converter("rgb");
    var interpolator = culori.interpolate([c1, c2], colorSpace);
    
    var points = Array.from(Array(steps).keys()).map(i => {
      return i / (steps - 1)
    })
    
    return points.map(decimal => {
      var pct = decimal * 100
      var color = culori.formatHex(rgb(interpolator(decimal)))
      
      if (opts.format == 'integer') {
        return parseInt(color.replace(/^#/, ''), 16)
      } else if (opts.format == 'hex') {
        return color
      } else if (opts.format == 'string') {
        return `${color}`
      }
    })
  }
  
  backgroundGradient() {
    var returnedArray = this.gradientStopArray({format: 'string'})
    
    var string = returnedArray.map((color, idx) => {
      var decimal = idx / (returnedArray.length - 1)
      
      return `${color} ${decimal * 100}%`
    })
    
    if (this.state.amendmentGradientType == 'linear') {
      return `linear-gradient(${this.state.gradientAngle}deg, ${string})`
    } else {
      return `radial-gradient(at 50% 100%, ${string})`
    }
  }
  
  // async setENS() {
  //   var ensName = await this.reverse(this.props.address);
    
  //   this.setState({ensName})
  // }
  
  // async reverse(address) {       
  //   if (!address) { return }
    
  //   var lookup=address.toLowerCase().substr(2) + '.addr.reverse'
  //   var ResolverContract=await this.props.web3.eth.ens.resolver(lookup);
  //   var nh=namehash.hash(lookup);
  //   var name=await ResolverContract.methods.name(nh).call()
  //   return name;
  // }
  
  price() {
    return this.state.isFancy ? this.fancyCostString() + " + Gas" : "$0 + Gas"
  }

  // truncatedAddress(address) {
  //   return <span>{address.slice(0, 6)}...{address.slice(-4)}</span>
  // }

  handleSubmit = async (e) => {

      
    e.preventDefault()
    
    if (!this.messageBoardContract()) {
      return false
    }
    
    console.log(this.props.writeContracts)
    // return
    // const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, USE_BURNER_WALLET);
    // const userSigner = userProviderAndSigner.signer;
    
    
    const tx = this.props.tx
    var returned
    // const tx = Transactor(userSigner, gasPrice);
    
    this.setState({isSubmitting: true})
    
    if (this.state.isFancy) {
      var gradientStops = this.gradientStopArray({format: 'integer'})
      
      var params = [
        this.state.amendmentText,
        parseInt(this.state.amendmentTextColor.replace(/^#/, ''), 16),
        this.state.amendmentGradientType == 'radial',
        this.state.fontSize,
        this.state.gradientAngle,
        gradientStops,
        this.state.recipient || '0x0000000000000000000000000000000000000000',
        this.state.inReplyToMessageId || 0,
        {value: this.fancyCost()}
      ]
      
      
      returned = await tx(this.messageBoardContract().createFancyMessage(...params))
    } else {
      returned = await tx(this.messageBoardContract()["createBasicMessage(string,address,uint256)"](
        this.state.amendmentText,
        this.state.recipient || '0x0000000000000000000000000000000000000000',
        this.state.inReplyToMessageId || 0
      ))
    }
    
    console.log(returned)
    
    this.setState({isSubmitting: false})
    
    if (returned) {
      var nextTokenId = this.state.nextTokenId + 1
      
      this.setState(this.initialState())
      
      this.setState({nextTokenId: nextTokenId, success: true})
      
      window.setTimeout(() => {
        this.setState({success: false})
      }, 7500)
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    
    this.setState({
      [name]: value,
      currentTextBytes: (new TextEncoder().encode(this.state.amendmentText)).length,
      success: false
    });
  }

  render() {
    return (
      <form className={`amendForm calldataForm
      ${this.state.success ? "success" : ""}
      status_${this.state.formStatus}`}
      
          onSubmit={this.handleSubmit}
          style={{display: `${this.props.hidden ? 'none' : 'flex'}`}}
      >
        
        <Result
    status="success"
    title="Message Sent!"
    subTitle="Your message is on the blockchain and will appear on your page shortly"
    extra={[
      <Button href={"/" + this.props.resolvedAddress} type="primary" key="console">
        Go to your page
      </Button>
    ]}
  />
        
      <div className="newAmendUnitContainer">
        <div className="message noScale" style={{
          color: `${this.state.isFancy ? this.state.amendmentTextColor : "#000"}`,
          background: `${this.state.isFancy ? this.backgroundGradient() : "#fff"}`,
        }}>

          <div className="messageText" style={{position: 'relative'}}>
            {/* <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,.25)",
              zIndex: '1',
              position: 'absolute',
              top: 0,
              left: '50%'
            }}
            
            ></div> */}
            <textarea
            style={{
              fontSize: this.calculatedFontSize()[0],
              lineHeight: this.calculatedFontSize()[1],
              letterSpacing: this.calculatedFontSize()[2]
            }}
            onChange={this.handleInputChange}
            onInput={this.handleInputChange}
            onKeyPress={this.handleInputChange}
            onKeyUp={this.handleInputChange}
            onKeyDown={this.handleInputChange}
            onPaste={this.handleInputChange}
            value={this.state.amendmentText}
            name='amendmentText'
            className="messageTextInner"
            maxLength="10240"
            placeholder='Your message. What you write he can never be changed by anyone—including you.'
            required></textarea>
          </div>

          <div className="messageFooter">
            <div className='tokenName'>
              FOREVER MSG
            </div>

            <div className='tokenMeta'>
              from&nbsp;&nbsp;&nbsp;<Address address={this.props.resolvedAddress && this.props.resolvedAddress.toLowerCase()} ensProvider={this.props.ensProvider}
                size="long" fontSize={12} noCopy={true} blockExplorer={"/"} /><br></br>
              date&nbsp;&nbsp;&nbsp;{(this.state.currentTime).toLocaleString(
                'sv-SE', {
                  month: 'numeric', day: 'numeric', year: 'numeric',
                timeZone: 'UTC'
              }
              )}
            </div>
          </div>
        </div>
        
      </div>
        
        <div className="controls"><ReactTooltip />
          <div data-tip={`${this.state.isFancy ? '' : 'Enable Fancy Message mode to use colors!'}`} className="inputs" style={{
            opacity: `${this.state.isFancy ? "1" : "0.25"}`,
          }}>
            <div className="gradientOptions">
            <label className="doodle-border-bottom">
              <select name="fontSize" defaultValue="16" style={{ padding: "5px 15px 5px 2px" }} onChange={this.handleInputChange}>
                {[14, 16, 20, 24, 28, 32, 42, 60, 76, 92].map((size, index) => {
                  return <option key={index} value={size}>{size}</option>
                })}
              </select>
                Font Size
            </label>
            <div className="doodle-border-bottom">Colors</div>
              <div>
                <label>
                  <div className="colorPickerContainer">
                    <input name="amendmentTextColor" type="color"
                      value={this.state.amendmentTextColor}
                      onChange={this.handleInputChange}></input>
                   </div>
                  Text Color
                </label>
              </div>

              <div>
                <label>
                  <div className="colorPickerContainer">
                    <input name="amendmentGradientColor1" type="color"
                      value={this.state.amendmentGradientColor1}
                      onChange={this.handleInputChange}></input>
                  </div>

                  Background Color 1
                </label>
              </div>

              <div>
                <label>
                  <div className="colorPickerContainer">
                    <input name="amendmentGradientColor2" type="color"
                      value={this.state.amendmentGradientColor2}
                      onChange={this.handleInputChange}></input>
                   </div>
                   Background Color 2
                </label>
              </div>
              
              <div className="doodle-border-bottom">Gradient Type</div>
              <label>
                <input name="amendmentGradientType" type="radio"
                  value="linear"
                  checked={this.state.amendmentGradientType === "linear"}
                  onChange={this.handleInputChange}></input>
                Linear
              </label>
              <label>
                <input name="gradientAngle" value={this.state.gradientAngle}
                type="number"
                style={{border: "1px solid #ccc", marginLeft: 20, width: 65, padding: "1px 5px"}}
                min="0" max="360" step="15" onChange={this.handleInputChange}></input>
                Gradient angle
              </label>
              <label>
                <input name="amendmentGradientType" type="radio"
                  value="radial"
                  checked={this.state.amendmentGradientType === "radial"}
                  onChange={this.handleInputChange}></input>
                Radial
              </label>
              <label>
                <input name="amendmentGradientType" type="radio"
                  value="none"
                  checked={this.state.amendmentGradientType === "none"}
                  onChange={this.handleInputChange}></input>
                No Gradient
              </label>
              <div className="doodle-border-bottom">Gradient Style</div>
              <div style={{display: 'flex', gap: 10}}>
                <label>
                  <input name="gradientColorSpace" type="radio"
                    value="oklab"
                    checked={this.state.gradientColorSpace === "oklab"}
                    onChange={this.handleInputChange}></input>
                  Regular
                </label>
                <label>
                  <input name="gradientColorSpace" type="radio"
                    value="oklch"
                    checked={this.state.gradientColorSpace === "oklch"}
                    onChange={this.handleInputChange}></input>
                  Vivid
                </label>
              </div>

            </div>
          </div>
          <div className="submitArea">
            <Spin spinning={this.state.isSubmitting}>
            {/* <label style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              marginBottom: '25px'
            }}>
              <input type="checkbox" name="isFancy" checked={this.state.isFancy} onChange={this.handleInputChange} />
              Send a fancy message (NFT)?
            </label> */}
            
            <Checkbox
              style={{marginBottom: 15, marginTop: 15, width: "100%"}}
            name="isFancy" checked={this.state.isFancy} onChange={this.handleInputChange}>
              <b>Make my Message an NFT
                <br></br>
                <a href="#" onClick={this.toggleModal}>What does this mean?</a>
                </b></Checkbox>

              <Modal title="Fancy Messages (NFTs) v. Basic Messages"
                cancelButtonProps={{ style: { display: 'none' } }}
                visible={this.state.modalVisible}
                onOk={this.toggleModal}
                onCancel={this.toggleModal}>
                <p>
                  You can send two types of messages with Forever Message: Basic Messages and Fancy Messages. Basic Messages are the most affordable way to get started with Forever Message. They are plain text messages that are stored in Ethereum logs through Solidity events. Basic Messages are 100% free to send—all you pay is the gas.
                </p>

                <p>
                  However, Basic Messages have some limitations. Though they last forever, Basic Messages might eventually become difficult to access without depending on a centralized log repository (like Etherscan), managing your own log storage, or reconstructing Ethereum logs when you want to get a message. If you send a lot of messages over a long period of time this could become cumbersome and so you should consider sending Fancy Messages instead.
                </p>

                <p>
                  Fancy messages are ERC-721 NFTs. This means they are always instantly available, they are unique, can be bought and sold, and, best of all, you can customize Fancy Messages by choosing a text color and a background gradient. Each Fancy Message has an NFT image that is stored on-chain as an SVG.
                </p>
              </Modal>
            
              
              <div className="price" style={{width: "75%"}}>
                Bytes: {this.state.currentTextBytes}
              </div>
              
              <div className="price" style={{width: "75%"}}>
                Price: {this.price()}
              </div>
              
              { this.messageBoardContract() ?
                <Button block htmlType="submit" type='primary'>Send</Button>
                :
                <Button
                key="loginbutton"
                type='primary'
                onClick={this.props.loadWeb3Modal}
              >
                Connect your wallet to send
              </Button>
              }
            </Spin>
          </div>
        </div>
      </form>
    )
  }
}

export default MessageForm;
