import React from "react";
import { Link } from "react-router-dom";
// import { useContractReader } from "eth-hooks";
// import { ethers } from "ethers";
import { Button, Divider, Typography, Layout, Tabs} from "antd";
import { useLookupAddress } from "eth-hooks/dapps/ens";
import { useEventListener } from "eth-hooks/events/useEventListener";
import { ethers } from "ethers";

import Message from "./Message";
import "./Message.scss";

/**
 * web3 props can be passed from '../App.jsx' into your local view component for use
 * @param {*} yourLocalBalance balance on current network
 * @param {*} readContracts contracts from current chain already pre-loaded using ethers contract module. More here https://docs.ethers.io/v5/api/contract/contract/
 * @returns react component
 */
function Home({ localProvider, readContract, loadWeb3Modal, blockExplorer, openSea,
                messageForm, mainnetProvider, address, price }) {
  
  const { Title, Paragraph, Text } = Typography;
  
  const { TabPane } = Tabs;
  
  var lookedUpAddress = useLookupAddress(mainnetProvider, address)
  
  var fancyBaseCost = ethers.BigNumber.from(8758200 * 1e9)
  var fancyBaseEther = ethers.utils.formatEther(fancyBaseCost)
  var fancyBaseUSD = (fancyBaseEther * price).toFixed(2)
  
  var fancyVariableCost = ethers.BigNumber.from(21600 * 1e9)
  var fancyVariableEther = ethers.utils.formatEther(fancyVariableCost)
  var fancyVariableUSD = (fancyVariableEther * price).toFixed(2)
  
  var tweetCost = ((fancyBaseEther * price) + (fancyVariableEther * price * 280)).toFixed(2)
  
  const events = useEventListener(readContracts, "ForeverMessage", "CreateMessage", localProvider, 1);
  
  var decimalToHex = function(d, padding = 6) {
    var hex = Number(d).toString(16);
    padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

    while (hex.length < padding) {
      hex = "0" + hex;
    }

    return "#" + hex;
  }
  
  const foreverMessageAddress = readContracts.ForeverMessage && readContracts.ForeverMessage.address
  
  var basicMessages = events.map(event => {
    return {
      text: event.args.text,
      timestamp: event.args.sentAt,
      senderAddress: event.args.sender,
      messageId: parseInt(event.args.messageId),
      fancyMessageId: parseInt(event.args.fancyMessageId),
      recipient: event.args.recipient,
      inReplyToMessageId: parseInt(event.args.inReplyToMessageId),
      textColor: decimalToHex(event.args.textColor),
      transactionHash: event.transactionHash,
      contract: event.address,
      gradientColors: event.args.gradientColors,
      isRadialGradient: event.args.isRadialGradient,
      linearGradientAngleDeg: event.args.linearGradientAngleDeg,
      fontSize: event.args.fontSize
    }
  })
    
  const { Content } = Layout
  
  return (
    <Content style={{fontSize: 16, paddingTop: 50}}>
      {/* <img src="/bubble.png"></img> */}
      {/* <h1 style={{textAlign: 'center', backgroundImage: "url(/bubble.png)", backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center', height: 200, display: "flex", alignItems: 'center', justifyContent: 'center',
      paddingBottom: 35, color: 'white'}}>Welcome to foreverMessage!</h1> */}
      
      {/* <h1>Welcome to foreverMessage</h1> */}
      <Title level={2}>Welcome to Forever Message</Title>
      {/* <Title level={2}>Welcome to foreverMessage</Title> */}
      {/* <Title level={4}>What would you say if you knew it would last forever?</Title> */}
      
      {/* <h2 style={{textAlign: 'center'}} >💬</h2> */}
      
      {/* <h4 style={{marginBottom: '15px'}}>
        What would you say if you knew it would last forever?
      </h4> */}
      
      <p style={{fontSize: 21}}>
      Forever Message empowers everyone to post messages that cannot be deleted, restricted, or tampered with in any way. Your Forever Messages will be available to the entire world exactly as you composed them <i>forever</i>.
      </p>
      
      <p>
      Forever Message works by storing your message on the Ethereum blockchain with no external dependencies. Because no one controls the blockchain, <b>no one can censor your message</b>.
      </p>
      
      <p>
        To learn more: <a href="https://etherscan.io/address/0x94f3b9ba57f8ecd2b4ae244e55f2d4f8560fc9c2#code">read the code here</a> and the inspiration <a onClick={(e) => {
          window.location = location.href.replace(location.hash, "") + "#forever-message-inspiration"
          window.location.reload()
          e.preventDefault()
          }} href="#">below</a>.
      </p>


      <Tabs defaultActiveKey={window.location.hash} centered={true} onChange={(activeKey) => {window.location.hash = activeKey}}>
        <TabPane tab="Send a Forever Message" key="#send-a-forever-message">
          <p>
            {address && "Once you send your message you can check it out on your page: "}
            {
              address && <Link to={"/" + lookedUpAddress}>
                {location.href.replace(location.hash, "")}{lookedUpAddress}
              </Link>
            }

            {/* {(messageForm && " your page")} */}
          </p>

          {messageForm}
        </TabPane>
        <TabPane tab="Forever Message Inspiration" key="#forever-message-inspiration" style={{ fontSize: 16, maxWidth: 700 }}>
          <h2 id="why-forever-message">Why Forever Message?</h2>
          <p>Today a significant percentage of all human communication happens on platforms controlled by a small number of giant tech companies. As this percentage increases, centralized corporate control over communication technology verges on centralized corporate control over communication itself.</p>
          <p>This is not a good thing.</p>
          <p>To maintain their dominant positions, corporations have no choice but to use this power to further their own interests, and we cannot rely on their interests aligning with humanity’s in the long term. Even if, at a given time, giant companies seem to be doing a good job of censoring true misinformation, or actively refraining from censoring altogether, the “good guys” won’t be in charge forever.</p>
          <p>Complete power over human communication is too dangerous for <em>any</em> group of people to hold.</p>
          <h2 id="what-is-forever-message">What is Forever Message?</h2>
          <p>Forever Message’s mission is to prevent total centralized control over ideas by giving everyone on Earth the ability to post messages that cannot be deleted, restricted, or tampered with in any way—by enabling everyone to say something that will last forever.</p>
          <p>Forever can help you communicate an idea that is currently banned, an idea that is innocuous today but might become controversial in the future, or simply a personal message or tribute you want to survive through the ages.</p>
          <p>For a message to last forever, it must possess two properties:</p>
          <ol>
            <li>No one has the power to delete it.</li>
            <li>Many have the incentive to maintain its availability.</li>
          </ol>
          <p>This is why Forever builds on the Ethereum blockchain—the most widely used and battle-tested decentralized cloud computing platform. Every transaction in Ethereum depends on the integrity of all previous transactions. This means everyone who uses Ethereum has the incentive to protect your message, because without your message Ethereum cannot function.</p>
          <p>Ethereum is often criticized for being, on average, about a billion times more expensive to use than AWS. However, for the censorship-resistance use-case, Ethereum is infinitely cheaper than AWS because there is no amount of money you could pay Amazon to get them to forgo their right to delete your data.</p>
          <p>Enabling unimpeded human expression is Ethereum’s “killer app” and Forever Message endeavors to make it as widely available as possible.</p>
        </TabPane>
        {/* <TabPane tab="Tab 3" key="3">
          Content of Tab Pane 3
        </TabPane> */}
        <TabPane tab="All Forever Messages" key="#all-forever-messages">
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '25px', transform: 'scale(1)', transformOrigin: 'top left',
          position: 'relative', alignItems: 'flex-start', marginTop: 25
        }}>
          {basicMessages.slice().reverse().map((message, i) => 
            <>
              {/* <Space>
                <Button type="link" href={blockExplorer + "tx/" + message.transactionHash}>
                  Etherscan
                </Button>
                {message.tokenId > 0 &&
                  <Button type="link" href={openSea + message.contract + "/" + message.tokenId}>
                    OpenSea
                  </Button>
                }
              </Space> */}
              <div className="messageContainer">
                <Message key={i}
                  message={message}
                  ensProvider={mainnetProvider}
                  blockExplorer={blockExplorer}
                  openSea={openSea}
                ></Message>
              </div>
              
              <div style={{display: 'flex', gap: 15}}>
                {/* <a href={`https://testnets-api.opensea.io/api/v1/asset/${foreverMessageAddress}/${message.messageId}/validate`}>Validate</a> */}
                {/* <a href={`https://testnets-api.opensea.io/api/v1/asset/${foreverMessageAddress}/${message.messageId}/?force_update=true`}>View in OS API</a> */}
                {message.fancyMessageId > 0 && <a href={`${openSea}${foreverMessageAddress}/${message.messageId}`}>View on OpenSea</a>}
                <a href={blockExplorer + "tx/" + message.transactionHash}>Etherscan</a></div>
              

              {/* {console.log(imageSrcs[message.tokenId])} */}
              {/* { <img key={Math.random} src={imageSrcs[message.tokenId]} style={{width: 390, height: 'auto'}}></img>} */}
            </>
          )}
            
            {/* <div id="test" style={{width: 750, height: "100%", display: 'flex', flexDirection: 'column-reverse', gap: 10, justifyContent: 'flex-start', position: 'relative'}}>
              {svgs.map((svg, i) => {
                if (!svg.includes("nPg==")) {
                  return <img key={i} src={svg}></img>
                }
              })}
            </div> */}
          </div>
        </TabPane>
      </Tabs>


    </Content>
  );
}

export default Home;
